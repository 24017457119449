import levenshtein from 'fast-levenshtein'

export const PATTERN_EMAIL =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i

/**
 * Validate an email address looks like an email address.
 *
 * This function performs a simple regex test to see if the
 * string is a valid email. For a more comprehensive email
 * test see isValidEmail().
 *
 * @param {string} email The email address to validate.
 * @returns {boolean} True if the email address looks valid.
 */
export const validEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)

/**
 * Check if a string matches a pattern and if it
 * does, downcase it and return it.
 *
 * @param {string} string to downcase if {pattern} matches.
 * @param {RegExp} pattern to test {string} against.
 * @return {string} {string} downcased or empty string if {pattern.test} fails.
 */
const downCaseIfMatches = (string, pattern) => {
  if (!pattern.test(string)) return ''

  return string.toLowerCase()
}

/**
 * Check is an email string is valid.
 *
 * This function is a more comprehensive email validation
 * test. It not only checks that the email is a valid
 * string format, it also checks that the domain is spelled
 * correctly.
 *
 * @param {string} email The email address to validate.
 * @returns {boolean} True if the email address is valid.
 */
export const isValidEmail = (email) => {
  if (!email) return false
  email = email.toLowerCase()

  const absoluteDomains = [
    'aol.com',
    'bellsouth.net',
    'charter.net',
    'comcast.net',
    'cox.net',
    'earthlink.net',
    'email.com',
    'ge.com',
    'gm.com',
    'gmx.com',
    'gmail.com',
    'live.com',
    'hotmail.com',
    'icloud.com',
    'inbox.com',
    'juno.com',
    'mail.com',
    'me.com',
    'msn.com',
    'outlook.com',
    'sbcglobal.net',
    'verizon.net',
    'yahoo.com',
    'ymail.com',
  ]

  const isValid = [
    // simple regex validation of the full email address.
    () => downCaseIfMatches(email, PATTERN_EMAIL),
    // test that either:
    //    - the email domain exactly matches the above allow list
    //    - the levenshtein distance of the domain is not off by exactly one (most common mistake level)
    () =>
      [
        () => absoluteDomains.includes(email.split('@')[1]),
        () =>
          absoluteDomains.every(
            (domain) => levenshtein.get(email.split('@')[1], domain) !== 1
          ),
      ].some((f) => f()),
  ].every((f) => f())

  return Boolean(isValid)
}
