<script setup lang="ts">
const {
  meta: { showNotificationBar = false },
} = useRoute()
const { isExpanded } = useSearchSuggestions()
const { isReady, isLargeUp } = useSkyBreakpoint()

const navDrawerOpen = ref(false)
const isNavDrawerOpen = (navStatus: boolean) => {
  if (navStatus) {
    navDrawerOpen.value = navStatus
  } else {
    setTimeout(() => {
      navDrawerOpen.value = navStatus
    }, 400)
  }
}
</script>

<template>
  <header
    class="TheHeader"
    :class="{ 'TheHeader--sticky': isExpanded || navDrawerOpen }"
  >
    <TheNotificationBar v-if="isReady && showNotificationBar" />
    <TheHeaderNavigation
      :is-drawer-open="navDrawerOpen"
      @is-nav-drawer-open="isNavDrawerOpen"
    />
    <TheHeaderMenu v-if="isLargeUp" />
    <div v-if="!isReady" class="TheHeader_MenuPlaceholder" />
  </header>
</template>

<style lang="scss">
.TheHeader {
  background-color: var(--surface-color-dark);

  // sticky header must be above other sticky elements.
  &--sticky {
    position: sticky;
    top: 0;
    left: 0;
    z-index: calc(var(--z-index-sticky) + 1);
  }

  &_MenuPlaceholder {
    @include for-large-up {
      height: 50px;
      background-color: var(--surface-color-default);
    }
  }
}
</style>
