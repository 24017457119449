import { createSharedComposable } from '~/composables/utils/createSharedComposable'
import { refProxy } from '~/composables/utils/refProxy'

/**
 * Builds and returns the shared notification composable for the current customer.
 */
const useCustomerNotification = () => {
  const customerNotification = refProxy({
    title: ref(null),
    message: ref(null),
    link: ref(null),
    isDismissed: ref(false),
  })

  const { id: customerId } = useCustomer()
  const { isOfferApplied } = useOffer()

  const parseNotification = ({ attributes }) => {
    const notification = {
      title: attributes.title || null,
      message: attributes.message || null,
      link: attributes.link || null,
    }

    // The message and link are combined in `notifications/models.py`
    // Create an element to safely extract the link and message.
    if (attributes.message.startsWith('<a')) {
      const container = document.createElement('div')
      container.innerHTML = attributes.message
      notification.message = container.textContent
      if (!attributes.link) {
        notification.link = container.firstElementChild?.getAttribute('href')
      }
    }

    if (
      customerNotification.isDismissed.value &&
      customerNotification.message.value !== notification.message
    ) {
      customerNotification.isDismissed.value = false
    }

    customerNotification.title.value = notification.title
    customerNotification.message.value = notification.message
    customerNotification.link.value = notification.link
  }

  /**
   * Gets notification data from the API using the customer id.
   */
  const fetchNotifications = async () => {
    const response = await fetch(
      `/api/customer/${customerId.value}/notifications/`,
      {
        headers: {
          Accept: 'application/vnd.api+json',
        },
      }
    )

    if (response.ok && response.headers.get('content-type').includes('json')) {
      const { data } = await response.json()

      if (!data.length) {
        return
      }

      // The last notification in the list 'wins' and will be interpreted as the most important.
      const lastNotification = data.slice(-1)[0]

      parseNotification(lastNotification)
    }
  }

  watch(
    [customerId, isOfferApplied],
    () => {
      if (
        customerId.value &&
        isOfferApplied.value &&
        !customerNotification.title.value &&
        !customerNotification.message.value
      ) {
        fetchNotifications()
      }
    },
    { immediate: true }
  )

  return customerNotification
}

export default createSharedComposable(useCustomerNotification)
