<template>
  <div
    v-if="showNotification"
    class="TheNotificationBar"
    data-test-id="wrapper"
  >
    <div class="TheNotificationBar_Text">
      <SkyLink v-if="link" :to="link" dark inline data-test-id="link">
        <span
          v-if="title"
          class="TheNotificationBar_Title"
          data-test-id="title"
          >{{ title }}</span
        >
        <span v-if="message" data-test-id="text">{{ message }}</span>
      </SkyLink>
      <span v-else>
        <span v-if="title" class="TheNotificationBar_Title" data-test-id="title"
          >{{ title }}
          <span v-if="message"> : </span>
        </span>
        <span v-if="message" data-test-id="text">{{ message }}</span>
      </span>
    </div>
    <SkyButton data-test-id="close" icon dark @click="dismissNotification">
      <SkyIcon
        name="close"
        size="12"
        title="Close Notification"
        role="presentation"
      />
    </SkyButton>
  </div>
</template>

<script setup lang="ts">
const { title, message, link } = useNotification()

const isDismissed = ref<boolean>(false)

const showNotification = computed(
  () => (message.value || title.value) && !isDismissed.value
)
const dismissNotification = () => {
  isDismissed.value = true
}
</script>

<style lang="scss">
.TheNotificationBar {
  font-size: var(--font-size-75);
  display: flex;
  flex-grow: 1;
  gap: var(--spacing-2x);
  padding: var(--spacing-2x);
  color: var(--text-color-primary);
  background: var(--surface-color-leaf);
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: var(--line-height-tight);

  &_Title {
    font-family: var(--font-family-default-medium);
    font-weight: var(--font-weight-medium);
    margin-right: var(--spacing-1x);
  }

  // This style is necessary to align x icon with text.
  .SkyButton {
    display: flex;
  }
}
</style>
