<template>
  <SkyModal
    id="incentive-modal"
    ref="container"
    class="RoundModal"
    title="Email signup offer"
    title-hidden
    @close="handleClose(false)"
  >
    <div ref="content" class="RoundModal_Content">
      <SkyIcon
        class="RoundModal_Icon"
        name="gift"
        title="gift icon"
        size="24px"
        role="presentation"
      />
      <div class="RoundModal_Heading" data-test-id="heading">
        {{ heading }}
      </div>
      <div class="RoundModal_Subhead" data-test-id="subhead">
        {{ subhead }}
      </div>
      <SkyImage
        :src="
          transformImage(
            imageSrc,
            'f_auto,fl_progressive,w_700,ar_2:1,c_fill,q_auto'
          )
        "
        class="RoundModal_Image"
        data-test-id="image"
        alt="Product offer image"
      />
      <form class="RoundModal_Form" @submit.prevent="handleSubmit">
        <SkyInput
          id="round-modal-email"
          ref="input"
          v-model="email"
          autocomplete="email"
          label="Email Address"
          type="email"
          required
          :error="inputError"
          block
          @input="clearErrorMessage()"
        />
        <div
          class="RoundModal_Form_Submit"
          :class="{
            'RoundModal_Form_Submit--success': showFormSuccess,
          }"
          aria-label="Submit Email Address"
          data-test-id="submit-button"
          tabindex="0"
          @click="handleSubmit"
          @keyup.enter="handleSubmit"
        >
          <SkyIcon
            class="RoundModal_Form_Submit_Icon"
            :name="submitIconName"
            title="send icon"
            size="24px"
            role="presentation"
            data-test-id="send-icon"
          />
        </div>
      </form>
      <div class="RoundModal_Disclosure">
        *Valid only for new customers in the contiguous U.S. By submitting your
        email, you accept Grove’s
        <SkyLink tag="a" :to="PRIVACY_ROUTE" dark inline target="_blank"
          >Privacy Policy</SkyLink
        >
        and
        <SkyLink tag="a" :to="TERMS_ROUTE" dark inline target="_blank"
          >Terms of Use</SkyLink
        >.
      </div>
      <SkyButton
        ref="close"
        text
        inline
        class="RoundModal_Close"
        data-test-id="close"
        @click.stop="handleClose(false)"
      >
        Close
      </SkyButton>
    </div>
  </SkyModal>
</template>

<script>
import {
  SkyButton,
  SkyIcon,
  SkyImage,
  SkyInput,
  SkyLink,
  SkyModal,
} from '@groveco/skylight'
import { useNuxtApp } from '#app'
import { ROUTES } from '~/constants/routes'
import useCustomer from '~/composables/useCustomer'

import { isValidEmail } from '~/composables/utils/valid-email'
import { sha256 } from '~/composables/utils/hash'

export const INVALID_EMAIL = 'Please enter a valid email address.'

export default {
  components: {
    SkyButton,
    SkyIcon,
    SkyImage,
    SkyInput,
    SkyLink,
    SkyModal,
  },
  props: {
    /**
     * The heading of the modal.
     */
    heading: {
      type: String,
      required: true,
    },
    /**
     * The image src.
     */
    imageSrc: {
      type: String,
      required: true,
    },
    /**
     * The subhead text.
     */
    subhead: {
      type: String,
      required: true,
    },
  },
  emits: ['close'],
  data: () => ({
    email: '',
    hasValidEmail: true,
    isExistingCustomer: false,
    inputError: null,
    PRIVACY_ROUTE: ROUTES.privacy.to,
    showFormSuccess: false,
    submitIconName: 'send',
    TERMS_ROUTE: ROUTES.terms.to,
  }),
  mounted() {
    const { $events } = useNuxtApp()
    $events.emit(
      ...$events.appModalDisplayed({
        modal: {
          id: 'visitor-incentive-modal',
          name: 'visitor_incentive_modal',
        },
      })
    )
    this.$refs.input?.$el.focus()
  },
  methods: {
    /**
     * Closes the modal.
     *
     * @returns {void} Nothing.
     */
    handleClose(onSuccess = false) {
      this.$emit('close', onSuccess)
    },
    handleCustomerSignupErrors() {
      navigateTo({
        path: ROUTES.loginRoute.to,
        query: {
          email: this.email,
        },
        external: true,
      })
    },
    async handleSubmit() {
      this.validateEmail()
      if (this.hasValidEmail) {
        const { $events } = useNuxtApp()
        $events.emit(
          ...$events.appModalCtaClicked({
            modal: {
              id: 'visitor-incentive-modal',
              name: 'visitor_incentive_modal',
              text: 'Email Submitted',
            },
          })
        )
        this.submitIconName = 'spinner'
        const { id: customerId, updateCustomer } = useCustomer()
        try {
          await updateCustomer({ email: this.email })

          const customerContext = {
            id: String(customerId.value),
            isVisitor: false,
            hasMembership: false,
            hasUsablePassword: false,
          }

          $events.emit(
            ...$events.customerSignup({
              customer: customerContext,
              project: {
                name: 'grove-web',
              },
            })
          )

          window.dataLayer = window.dataLayer || []
          const hashedEmail = this.email ? await sha256(this.email) : null
          const gtm = window.dataLayer
          const customerDetails = {
            customerId: String(customerId.value),
            hashed_email: hashedEmail,
          }

          // Snowplow generic _legacy_ signup event / Google Analytics
          gtm.push({
            event: 'signup',
            ...customerDetails,
            isStaticPageEvent: true,
          })

          // Facebook track customer registration
          gtm.push({
            event: 'CompleteRegistration',
            ...customerDetails,
            isStaticPageEvent: true,
          })

          this.showFormSuccess = true
          this.submitIconName = 'checkmark'
          setTimeout(() => {
            this.handleClose(true)
          }, 1000)
        } catch (error) {
          this.handleCustomerSignupErrors()
        }
      }
    },
    clearErrorMessage() {
      this.hasValidEmail = true
      this.inputError = null
    },
    validateEmail() {
      this.hasValidEmail = Boolean(
        this.email !== '' && isValidEmail(this.email)
      )
      if (!this.hasValidEmail) {
        this.inputError = INVALID_EMAIL
      }
    },
  },
}
</script>

<style lang="scss">
.RoundModal {
  .SkyModal_Content,
  .SkyModal_Header {
    padding: 0;
  }

  .SkyInput_Container {
    box-sizing: content-box;
  }

  .SkyModal_Content {
    overflow: hidden;
    display: grid;
    align-items: center;
  }

  .SkyModal_Control {
    display: none;
  }

  .SkyModal_Container {
    position: absolute;
    z-index: var(--z-index-modal);
    top: 40%;
    left: 50%;
    height: 560px;
    width: 560px;
    transform: translate(-50%, -29%);
    border-radius: var(--border-radius-pill);
    background-color: var(--surface-color-leaf);
    box-shadow: 0 calc(var(--spacing-1x) * -1) var(--spacing-1x)
      rgba(0, 0, 0, 0.25);
    pointer-events: auto;

    @include for-medium-up {
      cursor: pointer;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &_Content {
    display: grid;
    justify-items: center;
    text-align: center;
    color: var(--text-color-primary);
    padding: 0 104px;
  }

  &_Heading {
    @include type-brand-heading('2');
    max-width: 80%;
    margin-top: var(--spacing-2x);

    /* stylelint-disable -- Line clamp */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    /* stylelint-enable */
  }

  &_Subhead {
    @include type-body('m');
    line-height: var(--line-height-tight);
    margin-top: var(--spacing-2x);

    /* stylelint-disable -- Line clamp */
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    /* stylelint-enable */
  }

  &_Disclosure {
    @include type-body('s');
    margin-top: var(--spacing-2x);
  }

  &_Icon {
    fill: var(--text-color-savings);
  }

  &_Image {
    margin: var(--spacing-2x) auto 0;
    max-width: 350px;
  }

  &_Form {
    margin-top: var(--spacing-4x);
    width: 350px;
    display: grid;
    grid-template-columns: 1fr 56px;
    column-gap: var(--spacing-2x);

    .SkyInput_Error {
      text-align: left;
      color: var(--text-color-primary);
    }

    &_Submit {
      background-color: var(--surface-color-dark);
      border-radius: var(--border-radius-default);
      display: grid;
      align-items: center;
      justify-content: center;
      height: 56px;

      &--success {
        background-color: var(--surface-color-savings);
      }

      &_Icon {
        fill: var(--text-color-action-hover-ondark);
      }
    }
  }

  &_Close {
    color: var(--text-color-primary);
    border-color: var(--text-color-primary);
    margin-top: var(--spacing-8x);

    &:hover,
    &:focus {
      color: var(--text-color-action-secondary-hover);
    }
  }
}
</style>
