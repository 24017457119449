<script setup lang="ts">
import { LOGOS } from '~/constants/logos'
import { ROUTES } from '~/constants/routes'

/**
 * Defines the header of the page.
 *
 */
const { homeRoute } = useCustomer()
</script>

<template>
  <SkyLink
    :to="homeRoute || ROUTES.visitorRoute.to"
    unstyled
    data-test-id="the-header-link"
    class="TheHeaderLogo"
  >
    <SkyImage
      class="TheHeaderLogo_Image"
      :src="LOGOS.wordmark.trademark"
      alt="Grove Collaborative logo"
    />
  </SkyLink>
</template>

<style lang="scss">
.TheHeaderLogo {
  border: none;
}

.TheHeaderLogo_Image {
  max-height: 2rem;
  max-width: 80px;

  @include for-large-up {
    max-height: unset;
    max-width: unset;
  }
}
</style>
