import { ref } from 'vue'
import type { Ref } from 'vue'
import { createSharedComposable } from '~/composables/utils/createSharedComposable'
import { requestUrl } from '~/composables/utils/constructor-io'
import { transformImage } from '~/utils/transformImage'

export interface SearchSuggestion {
  matchedTerms: string[]
  value: string
}
interface ProductSuggestion {
  productId: string
  productName: string
  brandName: string
  imageUrl: string
  productUrl: string
  imageAltText: string
}

interface SearchOptions {
  numSearchResults?: number
  numProductResults?: number
}

interface UseSearchSuggestions {
  productSuggestions: Ref<ProductSuggestion[]>
  searchSuggestions: Ref<SearchSuggestion[]>
  isBusy: Ref<boolean>
  isExpanded: Ref<boolean> // Whether or not the search suggestions component is expanded.
  clearSearchSuggestions: () => void
  fetchSearchSuggestions: (
    searchTerm: string,
    options?: SearchOptions
  ) => Promise<void>
}

const SEARCH_NUM_RESULTS = 7
const PRODUCTS_NUM_RESULTS = 6
export const PLACEHOLDER_IMAGE =
  'https://images.grove.co/upload/v1588037272/global/Line%20Illustrations/no-image-available.png'
export const IMAGE_TRANSFORMATIONS =
  'f_auto,fl_progressive,h_368,w_auto,q_auto,dpr_auto,ar_1:1,c_pad,b_white'

/**
 * Gets the search suggestions from the constructor.io API.
 */
const getSearchSuggestionData = (
  searchSuggestions: any[]
): SearchSuggestion[] => {
  return searchSuggestions.map((suggestion) => {
    return {
      matchedTerms: suggestion.matched_terms as string[],
      value: suggestion.value as string,
    }
  })
}

/**
 * Gets the product suggestions from the constructor.io API.
 */
const getProductSuggestionData = (
  productSuggestions: any[]
): ProductSuggestion[] => {
  return productSuggestions.map((suggestion) => {
    return {
      productId: suggestion.data.id,
      productName: suggestion.data.product_short_name,
      brandName: suggestion.data.brand_name,
      imageUrl: transformImage(
        suggestion.data.image_url || PLACEHOLDER_IMAGE,
        IMAGE_TRANSFORMATIONS
      ),
      productUrl: suggestion.data.url,
      imageAltText: suggestion.value,
    }
  })
}

const useSearchSuggestions = () => {
  const { id: customerId, locationId } = useCustomer()

  const suggestions: UseSearchSuggestions = {
    productSuggestions: ref([]),
    searchSuggestions: ref([]),
    isBusy: ref(false),
    isExpanded: ref(false),
    clearSearchSuggestions: () => {
      suggestions.productSuggestions.value = []
      suggestions.searchSuggestions.value = []
    },
    fetchSearchSuggestions: async (searchTerm, options = {}) => {
      suggestions.isBusy.value = true

      const {
        numSearchResults = SEARCH_NUM_RESULTS,
        numProductResults = PRODUCTS_NUM_RESULTS,
      } = options

      const request = requestUrl()
      request.pathname = `/autocomplete/${searchTerm}`

      try {
        await resolveRef({
          ref: customerId,
          timeout: 5000,
        })

        const userSegments = [
          // Customer location
          `location_${locationId.value || 'none'}`,
        ]

        // Add user segments to the request
        for (const segment of userSegments) {
          request.searchParams.append('us', segment)
        }

        // Constructor also needs the user segments as a global variable
        window._constructorio_segments = userSegments
      } catch {
        // Do not block suggestions if customer segments fail to resolve
      }

      request.searchParams.set(
        'num_results_Products',
        String(numProductResults)
      )
      request.searchParams.set(
        'num_results_Search Suggestions',
        String(numSearchResults)
      )
      request.searchParams.set('ui', customerId.value || '')

      if (locationId.value) {
        request.searchParams.set(
          'filters[Products][in_stock]',
          locationId.value
        )
      } else {
        request.searchParams.set('filters[Products][any_in_stock]', 'True')
      }

      const response = await fetch(request)

      if (
        response?.ok &&
        response.headers.get('content-type')?.includes('json')
      ) {
        const { sections, request } = await response.json()
        const {
          Products: productSuggestions,
          'Search Suggestions': searchSuggestions,
        } = sections
        const { term } = request

        suggestions.productSuggestions.value =
          getProductSuggestionData(productSuggestions)

        suggestions.searchSuggestions.value =
          getSearchSuggestionData(searchSuggestions)

        suggestions.isBusy.value = false

        // If there are no search suggestions, return a default search suggestion.
        if (!suggestions.searchSuggestions.value.length) {
          suggestions.searchSuggestions.value = [
            {
              matchedTerms: term.split(' '),
              value: term,
            },
          ]
        }
      } else {
        suggestions.isBusy.value = false
      }
    },
  }

  return suggestions
}

export default createSharedComposable(useSearchSuggestions)
