<script setup lang="ts">
import type { MenuItem } from '@groveco/http-services'
import { ROUTES } from '~/constants/routes'

/**
 * Dropdown menus for use in the Grove main header.
 */
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  active: {
    type: Boolean,
    default: false,
  },
  items: {
    type: Array<MenuItem>,
    default: () => [],
  },
})

const { hasPlacedOrder, isVip, isVipTrial } = useCustomer()

const activeModel = ref(false)
const dropdownWrapper = ref<InstanceType<typeof HTMLDivElement> | null>(null)

const isActive = computed(() => props.active || activeModel.value)
const itemContainerClasses = computed(() => {
  const baseClass = 'HeaderDropdown_ItemContainer'
  return {
    [baseClass]: true,
    [`${baseClass}--visible`]: isActive.value,
  }
})

const showVipBadge = computed<boolean>(
  () => (isVipTrial.value || !isVip.value) && hasPlacedOrder.value
)

const toggleActive = () => {
  activeModel.value = !activeModel.value
  if (isActive.value) {
    nextTick(() => {
      dropdownWrapper.value?.focus()
    })
  }
}

const handleBlur = (event: FocusEvent) => {
  // if focusing into a child item, don't break visibility of dropdown
  const currentTarget = event.currentTarget as HTMLElement
  if (!currentTarget?.contains(event.relatedTarget as Node)) {
    activeModel.value = false
  }
}
</script>

<template>
  <SkyButton
    class="HeaderDropdown"
    :aria-label="`open ${title} menu`"
    :aria-expanded="isActive"
    icon
    text
    @keyup.esc="activeModel = false"
    @click="toggleActive"
    @blur.capture="handleBlur"
    @mouseenter="activeModel = true"
    @mouseleave="activeModel = false"
  >
    <span class="HeaderDropdown_Title" data-test-id="header-dropdown-title">
      <slot name="title">
        {{ title }}
      </slot>
      <SkyIcon name="dropdown" size="16px" aria-hidden="true" />
    </span>
    <nav
      ref="dropdownWrapper"
      tabindex="0"
      aria-live="polite"
      :aria-label="label || title"
      :aria-hidden="!isActive"
      :hidden="!isActive"
      data-test-id="header-dropdown-wrapper"
    >
      <ul :class="itemContainerClasses">
        <li
          v-for="menuItem of items"
          :key="`header-dropdown-item-${menuItem.id}`"
          :class="{
            HeaderDropdown_Item: true,
            'HeaderDropdown_Item--featured': menuItem.id === 'account-refer',
          }"
          data-test-id="header-dropdown-item"
        >
          <SkyLink
            class="HeaderDropdown_Item_Link"
            dark
            block
            data-test-id="header-dropdown-item-link"
            :to="menuItem.href"
          >
            {{ menuItem.text }}
          </SkyLink>
          <template
            v-if="
              menuItem.text === ROUTES.membershipSettings.text && showVipBadge
            "
          >
            <SkyBadge
              class="HeaderDropdown_Item_Badge--join"
              data-test-id="join-badge"
              >Join</SkyBadge
            >
          </template>
        </li>
      </ul>
    </nav>
  </SkyButton>
</template>

<style lang="scss">
.HeaderDropdown {
  display: flex;
  position: relative;
  --text-color-action-primary: var(--text-color-action-primary-ondark);
  --text-color-action-primary-hover: var(--text-color-primary-ondark);

  &:hover {
    border-color: transparent;

    .HeaderDropdown_ItemContainer {
      visibility: visible;
      opacity: 1;
    }
  }

  &_Title {
    display: flex;
  }

  &_ItemContainer {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    margin: 0;
    left: 0;
    top: 98%;
    background: var(--surface-color-default);
    border: 1px solid var(--border-color-default);
    z-index: 10;
    min-width: 5rem;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
    text-align: left;
    list-style-type: none;
    padding-inline-start: 0;
    cursor: default;

    &--visible {
      visibility: visible;
      opacity: 1;
    }
  }

  &_Item {
    font-size: var(--font-size-200);
    padding: 0 var(--spacing-3x) 0 0;
    display: flex;

    &:first-child > * {
      padding-top: var(--spacing-4x);
    }

    &:last-child > * {
      padding-bottom: var(--spacing-4x);
    }

    &--featured {
      --font-family-default-medium: var(--font-family-display);
      background-color: var(--surface-color-cream);
      border-bottom: 1px solid var(--border-color-default);
      padding-bottom: var(--spacing-1x);
    }

    &_Badge--join {
      --surface-color-dark: var(--color-berry);
      align-self: center;
    }
  }

  &_Item_Link {
    padding: var(--spacing-3x) var(--spacing-1x) var(--spacing-3x)
      var(--spacing-4x);
    white-space: nowrap;
    border: none;
    line-height: 1;

    &:focus,
    &:hover:not([disabled]) {
      text-decoration: underline 2px;
    }
  }
}
</style>
