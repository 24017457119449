<script setup lang="ts">
import { watchDebounced } from '@vueuse/core'
import { FocusTrap } from 'focus-trap-vue'

import type { SearchSuggestion } from '~/composables/useSearchSuggestions'

const props = defineProps({
  showLongerPlaceholder: {
    type: Boolean,
    default: true,
  },
})

const SEARCH_DEBOUNCE = 300

const searchTerm = ref('')
const searchInput = ref<InstanceType<typeof SkyInput> | null>(null)
const searchResults = ref<InstanceType<typeof HTMLDivElement> | null>(null)
const searchResultsTopOffset = ref('0')

const route = useRoute()
const {
  fetchSearchSuggestions,
  clearSearchSuggestions,
  searchSuggestions,
  productSuggestions,
  isExpanded: isSearchActive,
} = useSearchSuggestions()

/**
 * Returns the placeholder text for the search input.
 */
const placeholderText = computed(() => {
  if (props.showLongerPlaceholder) {
    return 'Search sustainable essentials...'
  }
  return 'Search...'
})

/**
 * Determines if we show the X in input field to clear the search term.
 **/
const showClearButton = computed(() => {
  return searchTerm.value.length > 0 && isSearchActive.value
})

/**
 * Determines if search suggestions have been returned.
 */
const showSearchResults = computed(() => {
  return (
    isSearchActive.value &&
    Boolean(searchSuggestions.value.length || productSuggestions.value.length)
  )
})

/**
 * Clears the search input and resets the search suggestions.
 */
const handleSearchInputClear = () => {
  searchTerm.value = ''
  searchInput.value?.$el.focus()
}

/**
 * Hide the search results. Does not clear the search input or results.
 */
const closeSearchResults = () => {
  isSearchActive.value = false
  searchInput.value?.$el.dispatchEvent(new Event('blur', { bubbles: true }))
}

/**
 * Handles the search form submit event.
 */
const handleSubmit = () => {
  if (!searchTerm.value) {
    return
  }
  closeSearchResults()
  navigateTo(`/catalog/?q=${searchTerm.value}`, { external: true })
}

/**
 * Fetch the search suggestions if the search term is not empty.
 */
const onSearchTermUpdate = () => {
  if (!searchTerm.value.length) {
    clearSearchSuggestions()
    return
  }

  fetchSearchSuggestions(searchTerm.value)
}

/*
 *Highlights the matched search terms in the search suggestions.
 */
const highlightMatchedSearchTerms = (item: SearchSuggestion) => {
  const { matchedTerms, value } = item
  const terms = matchedTerms.join(' ')
  const matchedTermsRegex = new RegExp(terms, 'gi')

  const highlightedTerms = value
    .replace(matchedTermsRegex, `*${terms}*`)
    .split('*')
    .map((word) => ({ word, isHighlighted: !!word.match(matchedTermsRegex) }))
  return highlightedTerms
}

/**
 * Handles the click event on a search suggestions.
 */
const handleSuggestionClick = () => {
  closeSearchResults()
  clearSearchSuggestions()
}

/**
 * Sets the searchResultsTopOffset value, which is used to set the max-height of the search results.
 */
const setSearchResultTopOffset = () => {
  if (!searchResults.value) {
    return
  }
  const { top } = searchResults.value.getBoundingClientRect()
  searchResultsTopOffset.value = `${top}px`
}

// Watchers

watchDebounced(searchTerm, onSearchTermUpdate, {
  debounce: SEARCH_DEBOUNCE,
})

watch(searchResults, setSearchResultTopOffset)

watch(
  () => route.query,
  () => {
    const query = route.query.q
    searchTerm.value = typeof query === 'string' ? query : ''
  },
  { immediate: true }
)
</script>

<template>
  <FocusTrap
    :active="showSearchResults"
    escape-deactivates
    click-outside-deactivates
    :return-focus-on-deactivate="false"
    @deactivate="closeSearchResults"
  >
    <div
      class="SearchBarAutocomplete"
      :style="`--results-top-offset:${searchResultsTopOffset};`"
    >
      <div
        v-if="showSearchResults"
        class="SearchBarAutocomplete_Results_Backdrop"
        @click="closeSearchResults"
      ></div>
      <div class="SearchBarAutocomplete_Results_Container">
        <form
          class="SearchBarAutocomplete_Form"
          role="search"
          @submit.prevent="handleSubmit"
        >
          <div
            class="SearchBarAutocomplete_InputContainer"
            :class="{
              'SearchBarAutocomplete_InputContainer--WithClearBtn':
                showClearButton,
            }"
          >
            <SkyInput
              id="search-input"
              ref="searchInput"
              v-model="searchTerm"
              :aria-expanded="String(showSearchResults)"
              :placeholder="placeholderText"
              aria-autocomplete="both"
              aria-controls="searchTerms-suggestions product-suggestions"
              aria-haspopup="listbox"
              aria-label="search input"
              autocomplete="off"
              block
              class="SearchBarAutocomplete_Input"
              data-cnstrc-search-input
              inputmode="search"
              maxlength="240"
              @click="isSearchActive = true"
              @focus="isSearchActive = true"
            />
            <SkyButton
              v-if="showClearButton"
              type="button"
              icon
              aria-label="clear search"
              class="SearchBarAutocomplete_ClearButton"
              data-test-id="search-form-clear"
              @keydown.enter.space.prevent="handleSearchInputClear"
              @click.stop="handleSearchInputClear"
            >
              <SkyIcon
                aria-hidden="true"
                class="SearchBarAutocomplete_ClearX"
                name="close"
                size="12px"
                circled
              />
            </SkyButton>
          </div>
          <SkyButton
            type="submit"
            icon
            class="SearchBarAutocomplete_Submit"
            aria-label="Submit search"
            data-cnstrc-search-submit-btn
          >
            <SkyIcon
              class="SearchBarAutocomplete_SearchIcon"
              name="search"
              size="24px"
            />
          </SkyButton>
        </form>
        <div
          v-if="showSearchResults"
          ref="searchResults"
          class="SearchBarAutocomplete_Results"
        >
          <div
            class="SearchBarAutocomplete_Suggestions"
            data-cnstrc-autosuggest
          >
            <ul
              id="searchTerms-suggestions"
              class="SearchBarAutocomplete_SearchTerms"
              role="listbox"
            >
              <li
                v-for="(search, index) in searchSuggestions"
                :key="`search-${index}`"
                class="SearchBarAutocomplete_Suggestion"
                data-cnstrc-item-section="Search Suggestions"
                :data-cnstrc-item-name="search.value"
                @click="handleSuggestionClick"
              >
                <SkyLink :to="`/catalog/?q=${search.value}`" unstyled>
                  <span
                    v-for="(text, i) in highlightMatchedSearchTerms(search)"
                    :key="`text-${i}`"
                    :class="{
                      SearchBarAutocomplete_SearchTerms_Highlighted:
                        text.isHighlighted,
                    }"
                    >{{ text.word }}</span
                  >
                </SkyLink>
              </li>
            </ul>

            <div
              id="product-suggestions"
              class="SearchBarAutocomplete_Products"
            >
              <div
                v-for="(product, index) in productSuggestions"
                :key="`product-${index}`"
                class="SearchBarAutocomplete_Suggestion"
                data-cnstrc-item-section="Products"
                :data-cnstrc-item-name="product.productName"
                :data-cnstrc-item-id="product.productId"
                role="listbox"
              >
                <SkyLink
                  :to="product.productUrl"
                  unstyled
                  @click="handleSuggestionClick"
                >
                  <SkyImage
                    :src="product.imageUrl"
                    width="120"
                    height="120"
                    :alt="product.imageAltText"
                  />
                  <span class="SearchBarAutocomplete_Products_BrandName">
                    {{ product.brandName }}
                  </span>
                  <span
                    class="SearchBarAutocomplete_Products_ProductName"
                    :title="product.productName"
                  >
                    {{ product.productName }}
                  </span>
                </SkyLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </FocusTrap>
</template>

<style lang="scss">
.SearchBarAutocomplete {
  @mixin submit-active-state {
    background-color: var(--text-color-action-primary);
    &:hover,
    &:focus {
      background-color: var(--text-color-action-primary-hover);
    }
  }

  --border-radius: 20px;

  &_Form {
    background: var(--surface-color-default);
    border-radius: var(--border-radius);
    display: flex;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: calc(var(--height-input-default) - 8px);
    outline: none;
    border: 1px var(--color-navy) solid;

    // stylelint-disable
    &:focus-within {
      .SearchBarAutocomplete_Submit.SkyButton {
        @include submit-active-state;
      }

      .SearchBarAutocomplete_SearchIcon.SkyIcon {
        fill: var(--text-color-action-hover-ondark);
      }
    }
    // stylelint-enable

    @include for-large-up {
      border: none;
      overflow: visible;
    }
  }

  &_SearchIcon.SkyIcon {
    fill: var(--text-color-action-dark);
  }

  &_ClearButton {
    display: flex;
    align-items: center;
    padding: var(--spacing-2x);
    --text-color-action-primary: var(--text-color-secondary);
  }

  &_Submit.SkyButton {
    border: none;
    min-height: calc(var(--height-input-default) - 8px);
    width: var(--height-input-default);
    padding: var(--spacing-1x);
    border-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &:focus {
      background-color: var(--text-color-action-primary-hover);
    }
    &:active {
      background-color: var(--text-color-action-primary);
    }
    @include for-large-up {
      border-radius: 0 var(--border-radius) var(--border-radius) 0;
    }
  }

  &_InputContainer {
    width: 100%;
    display: grid;
    grid-template-columns: auto 0;

    &--WithClearBtn {
      grid-template-columns: auto 28px;
    }

    .SkyInput_Container {
      padding: 0;
      border: none;
      min-height: calc(var(--height-input-default) - 10px);
      background-color: transparent;
    }

    .SkyInput_InputContainer {
      margin: 0;
    }

    .SkyInput_Input {
      text-indent: calc(var(--spacing-2x) + 2px);
      margin: var(--spacing-2x) 0;
      border-radius: var(--border-radius);
    }

    @include for-large-up {
      font-size: var(--font-size-75);
    }
  }

  &_Results_Backdrop {
    position: absolute;
    width: 100%;
    top: 100%;
    right: 0;
    left: 0;
    height: 100vh;
    z-index: calc(var(--z-index-dropdown) - 1);
    background-color: rgba(0, 0, 0, 0.45);

    @include for-large-up {
      margin-top: 1px;
    }
  }

  &_Results_Container {
    @include for-small-up {
      position: relative;
    }
  }

  &_Results {
    position: absolute;
    width: auto;
    left: 0;
    top: 100%;
    padding: var(--spacing-4x);
    background-color: var(--surface-color-default);
    z-index: calc(var(--z-index-dropdown) + 1);
    box-shadow: var(--shadow-default-bottom);
    margin-top: 0;
    overflow-y: auto;
    overflow-x: hidden;
    height: fit-content;
    max-height: calc(100vh - var(--results-top-offset));

    @include for-small-up {
      margin-top: 5px;
    }

    @include for-large-up {
      width: 100vw;
      max-width: 375px;
    }
  }

  &_Suggestions {
    display: grid;
    grid-column-gap: var(--spacing-4x);

    // stylelint-disable-next-line
    @media only screen and (min-width: 87.5rem) {
      max-width: 87.5rem;
      margin: 0 auto;
    }
  }

  &_Suggestion {
    .SkyLink {
      @include type-body(m);
      display: block;
    }
  }

  &_SearchTerms {
    @include type-body(m);
    list-style: none;
    margin-left: 0;
    padding-inline-start: 0;
    margin-top: 0;

    &_Highlighted {
      @include type-body(m, true);
    }

    .SearchBarAutocomplete_Suggestion {
      padding: var(--spacing-2x) 0;
      border-bottom: 1px solid var(--border-color-default);

      // stylelint-disable-next-line
      .SearchBarAutocomplete_Suggestion {
        padding: var(--spacing-2x) 0 var(--spacing-2x) var(--spacing-4x);
      }
    }
  }

  &_Products {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    text-align: center;
    grid-column-gap: var(--spacing-2x);
    grid-row-gap: var(--spacing-2x);

    &_Item {
      max-width: 150px;
    }

    &_BrandName {
      display: block;
      @include type-body(s, true);
    }

    &_ProductName {
      @include type-body(s);
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      height: var(--spacing-8x);
    }
  }
}
</style>
