<script setup lang="ts">
import { FocusTrap } from 'focus-trap-vue'
import type { MenuItem } from '@groveco/http-services'

import { useDebounceFn } from '@vueuse/core'

// Constants
import {
  GROVE_WELLNESS_LOGO_IMAGE,
  WELLNESS_ITEM_ID,
} from '~/constants/wellness'
import { ROUTES } from '~/constants/routes'

type SkyButtonRef = InstanceType<typeof SkyButton> | null
interface ExpandedMenuItem extends MenuItem {
  parent: ExpandedMenuItem | null
}

const emit = defineEmits<{
  (e: 'hide'): void
}>()

const {
  id: customerId,
  hasPlacedOrder,
  isLoggedIn,
  isVip,
  isVipTrial,
  firstName,
} = useCustomer()

const displayName = computed<string>(() => {
  if (customerId.value && firstName.value) {
    return firstName.value
  }
  return 'Friend'
})

const { menuItems, educationItems } = useMenuItems()
const { appBadge } = useMobile()

const backButton = ref<SkyButtonRef>(null)
const expandedMenuItem = ref<ExpandedMenuItem | null>(null)
const menuItemRefs = ref<Record<string, SkyButtonRef>>({})
const touchStartX = ref(0)

const accountMenuItem: MenuItem = {
  id: 'account',
  children: [
    {
      id: 'account-settings',
      text: 'Your Settings',
      href: '/settings/account/',
      children: [],
    },
    {
      id: 'account-refer',
      text: 'Refer Friends (Get $30)',
      href: '/refer/',
      children: [],
    },
    {
      id: 'account-cart',
      text: ROUTES.cartRoute.text,
      href: ROUTES.cartRoute.to,
      children: [],
    },
    {
      id: 'account-membership',
      children: [],
      href: ROUTES.membershipSettings.to,
      text: ROUTES.membershipSettings.text,
    },
    {
      id: 'account-impact',
      children: [],
      href: ROUTES.impactRoute.to,
      text: ROUTES.impactRoute.text,
    },
    {
      id: 'account-favorites',
      text: 'Favorites',
      href: '/saved-products/',
      children: [],
    },
    {
      id: 'account-subscriptions',
      text: 'Product Subscriptions',
      href: ROUTES.subscriptions.to,
      children: [],
    },
    {
      id: 'account-order-history',
      text: 'Order History',
      href: '/order-history/',
      children: [],
    },
    {
      id: 'account-review',
      text: 'Review Products',
      href: '/review/',
      children: [],
    },
    {
      id: 'log-out',
      text: 'Log Out',
      href: ROUTES.logoutRoute.to,
      children: [],
    },
  ],
  text: 'Your Account',
  href: ROUTES.accountSettings.to,
}

const showVipDot = computed(() => {
  // Restore once useVipGiftPicker is ported
  // if (isVip.value) {
  //   const { hasNotification } = useVipGiftPicker()
  //   return hasNotification.value
  // }
  return false
})

const showVipBadge = computed<boolean>(
  () => (isVipTrial.value || !isVip.value) && hasPlacedOrder.value
)

const preloadGroveWellnessImages = () => {
  const topLogoImage = new Image()
  topLogoImage.src = GROVE_WELLNESS_LOGO_IMAGE
}

preloadGroveWellnessImages()

const expandMenuItem = (item: MenuItem) => {
  expandedMenuItem.value = {
    ...item,
    parent: expandedMenuItem.value,
  }

  // Shift focus to back button at the top
  nextTick(() => {
    backButton.value?.$el.focus()
  })
}

const handleReferClick = () => {
  navigateTo('/refer')
}

const showItemParent = () => {
  if (expandedMenuItem.value) {
    const { id } = expandedMenuItem.value
    expandedMenuItem.value = expandedMenuItem.value.parent
    // Shift focus back to menu item button
    nextTick(() => {
      menuItemRefs.value[id]?.$el?.focus()
    })
  }
}

const hideDrawer = () => {
  expandedMenuItem.value = null
  emit('hide')
}

const handleTouchEnd = (event: TouchEvent) => {
  const { changedTouches = [] } = event
  const touchEndX = changedTouches[0].screenX
  const travel = Math.abs(touchStartX.value - touchEndX)

  // Ignore short swipes
  if (travel < window.innerWidth / 2) {
    return
  }

  // Hide when swiping left or if the menu is not expanded
  if (touchStartX.value >= touchEndX || !expandedMenuItem.value) {
    hideDrawer()
    return
  }

  showItemParent()
}

const handleTouchStart = (event: TouchEvent) => {
  const { changedTouches = [] } = event
  touchStartX.value = changedTouches[0].screenX
}

const isGroveWellness = (menuItem: MenuItem) => menuItem.id === WELLNESS_ITEM_ID

const EDUCATION_ID_LIST = ['mission', 'standards', 'how-it-works']

const isEducationItem = (menuItem: MenuItem) =>
  EDUCATION_ID_LIST.includes(menuItem.id)

const drawerTopOffset = ref('0')

const setDrawerOffset = () => {
  const headerNavEle = document.querySelector('.TheHeaderNavigation')
  const headerNavBoundingRect = headerNavEle?.getBoundingClientRect()
  const top = headerNavBoundingRect?.bottom
  drawerTopOffset.value = `${top}px`
}

const handleScroll = useDebounceFn(() => {
  setDrawerOffset()
}, 100)

onMounted(() => {
  setDrawerOffset()
  window.addEventListener('scroll', handleScroll)
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>

<template>
  <div
    class="NavDrawer"
    :style="`--drawer-top-offset:${drawerTopOffset};`"
    data-test-id="nav-drawer"
    @touchstart="handleTouchStart"
    @touchend="handleTouchEnd"
  >
    <FocusTrap
      active
      fallback-focus=".NavDrawer_Container"
      click-outside-deactivates
      :return-focus-on-deactivate="false"
      @deactivate="hideDrawer"
    >
      <div
        class="NavDrawer_Container"
        :class="{
          'NavDrawer_Container--white-background':
            expandedMenuItem && !isEducationItem(expandedMenuItem),
        }"
        tabindex="0"
      >
        <nav v-if="!expandedMenuItem">
          <div class="NavDrawer_Header NavDrawer_Header_Account">
            <template v-if="customerId">
              <svg
                role="presentation"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
              >
                <path
                  fill-rule="evenodd"
                  d="M2.251 17.1A10.952 10.952 0 0 1 1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11a10.98 10.98 0 0 1-8.656-4.21c0-.001 0 0 0 0a11.013 11.013 0 0 1-1.093-1.69Zm16.777.522A8.983 8.983 0 0 1 12 21a8.983 8.983 0 0 1-7.028-3.377 13.319 13.319 0 0 1 4.53-1.658 1 1 0 0 0 .533-1.695c-.69-.685-1.159-1.74-1.159-2.957 0-2.256 1.538-3.813 3.125-3.813s3.125 1.557 3.125 3.813c0 1.217-.468 2.272-1.158 2.957a1 1 0 0 0 .532 1.695 13.32 13.32 0 0 1 4.529 1.657Zm1.068-1.685a15.136 15.136 0 0 0-3.718-1.595c.478-.89.748-1.93.748-3.03 0-3.06-2.155-5.812-5.125-5.812s-5.125 2.753-5.125 5.813c0 1.1.27 2.14.749 3.03a15.133 15.133 0 0 0-3.72 1.594 9 9 0 1 1 16.191 0Z"
                  clip-rule="evenodd"
                />
              </svg>
              <SkyButton
                v-if="isLoggedIn"
                text
                @click.stop="expandMenuItem(accountMenuItem)"
              >
                <span
                  class="NavDrawer_Header_Account_Greeting"
                  data-test-id="account-display-name"
                >
                  Hello, {{ displayName }}!</span
                >
                <div data-test-id="account-expand">
                  {{ accountMenuItem.text }}
                </div>
              </SkyButton>
              <SkyLink
                v-else
                :to="ROUTES.loginRoute.to"
                data-test-id="login-link"
                @click="emit('hide')"
              >
                <span class="NavDrawer_Header_Account_Greeting"
                  >Hello, friend!</span
                >
                <div>Log in</div>
              </SkyLink>
              <SkyButton
                v-if="isLoggedIn"
                class="NavDrawer_Header_ReferButton"
                data-test-id="refer-button"
                outlined
                dense
                @click="handleReferClick"
              >
                Refer Friends, Get $30
              </SkyButton>
            </template>
          </div>
          <ul class="NavDrawer_List">
            <li v-if="isLoggedIn" class="NavDrawer_ListItem">
              <SkyLink
                :to="ROUTES.vipHub.to"
                :class="{
                  'NavDrawer_MenuItem--dot': showVipDot,
                }"
                class="NavDrawer_MenuItem NavDrawer_MenuItem--primary NavDrawer_MenuItem--vip"
                data-test-id="viphub-link"
                @click="emit('hide')"
              >
                {{ !isVip ? 'Join VIP' : 'VIP Hub' }}
              </SkyLink>
            </li>
            <li
              v-for="menuItem in menuItems"
              :key="menuItem.id"
              class="NavDrawer_ListItem"
            >
              <SkyButton
                v-if="menuItem.children.length"
                :ref="(el) => (menuItemRefs[menuItem.id] = el as SkyButtonRef)"
                class="NavDrawer_MenuItem NavDrawer_MenuItem--primary"
                data-test-id="item-expand"
                right-icon="chevron-right"
                text
                @click.stop="expandMenuItem(menuItem)"
              >
                {{ menuItem.text }}
              </SkyButton>
              <SkyLink
                v-else
                :to="menuItem.href"
                class="NavDrawer_MenuItem NavDrawer_MenuItem--primary"
                data-test-id="item-link"
                @click="emit('hide')"
              >
                {{ menuItem.text }}
              </SkyLink>
            </li>
          </ul>
          <ul class="NavDrawer_List NavDrawer_List_Education">
            <li
              v-for="menuItem in educationItems"
              :key="menuItem.id"
              class="NavDrawer_ListItem"
            >
              <SkyButton
                v-if="menuItem.children.length"
                :ref="(el) => (menuItemRefs[menuItem.id] = el as SkyButtonRef)"
                class="NavDrawer_MenuItem"
                data-test-id="item-expand"
                right-icon="chevron-right"
                text
                @click.stop="expandMenuItem(menuItem)"
              >
                {{ menuItem.text }}
              </SkyButton>
              <SkyLink
                v-else
                :to="menuItem.href"
                class="NavDrawer_MenuItem"
                data-test-id="item-link"
                @click="emit('hide')"
              >
                {{ menuItem.text }}
              </SkyLink>
            </li>
            <li class="NavDrawer_BottomImages">
              <SkyImage
                :src="
                  transformImage(
                    'https://images.grove.co/upload/v1554378363/global/Icons/svg-icons/b-corp.svg',
                    'f_auto,fl_progressive,w_112,q_auto'
                  )
                "
                alt="B Corp logo"
                width="40"
              />
              <SkyImage
                :src="
                  transformImage(
                    'https://images.grove.co/upload/v1696606761/global/Logos/BeyondPlastic/Beyond-Plastic-Logo.svg',
                    'f_auto,fl_progressive,w_112,q_auto'
                  )
                "
                alt="Beyond Plastic logo"
                width="56"
              />
              <SkyLink
                v-if="appBadge"
                class="NavDrawer_MenuItem"
                data-test-id="mobile-link"
                rel="noreferrer noopener"
                target="_blank"
                to="https://grove.onelink.me/rUOP/daabd0e9"
              >
                <SkyImage
                  :src="appBadge"
                  alt="Download the Grove App"
                  class="NavDrawer_AppBadge"
                />
              </SkyLink>
            </li>
          </ul>
        </nav>
        <nav
          v-else
          :aria-labelledby="`expandedMenuItem-${expandedMenuItem.id}`"
        >
          <div
            class="NavDrawer_Header"
            :class="{
              NavDrawer_Header_Education: isEducationItem(expandedMenuItem),
            }"
          >
            <SkyButton
              ref="backButton"
              class="NavDrawer_MenuItem NavDrawer_MenuItem--back"
              data-test-id="back"
              left-icon="chevron-left"
              text
              @click.stop="showItemParent"
            >
              Back
            </SkyButton>
          </div>
          <SkyLink
            v-if="isGroveWellness(expandedMenuItem)"
            :to="ROUTES.wellnessRoute.to"
            unstyled
            data-test-id="expanded-heading-grove-wellness"
          >
            <SkyImage
              alt="Grove Wellness Logo"
              loading="eager"
              class="NavDrawer_GroveWellnessLogo"
              :src="GROVE_WELLNESS_LOGO_IMAGE"
              width="140"
            />
          </SkyLink>
          <h2
            v-else-if="isEducationItem(expandedMenuItem)"
            :id="`expandedMenuItem-${expandedMenuItem.id}`"
            class="NavDrawer_Heading"
            :class="{
              NavDrawer_Heading_Education: isEducationItem(expandedMenuItem),
            }"
            data-test-id="expanded-heading"
          >
            {{ expandedMenuItem.text }}
          </h2>
          <SkyLink
            v-else
            class="NavDrawer_Heading_Link"
            :to="expandedMenuItem.href"
            @click="emit('hide')"
          >
            <h2
              :id="`expandedMenuItem-${expandedMenuItem.id}`"
              class="NavDrawer_Heading"
              data-test-id="expanded-heading"
            >
              {{ expandedMenuItem.text }}
            </h2>
            <div
              v-if="expandedMenuItem.href.includes('/catalog')"
              class="NavDrawer_Heading_Link_ShopAll"
            >
              Shop All
            </div>
          </SkyLink>
          <ul
            class="NavDrawer_List"
            :class="{
              NavDrawer_List_Education: isEducationItem(expandedMenuItem),
            }"
          >
            <li
              v-for="child in expandedMenuItem.children"
              :key="child.id"
              class="NavDrawer_ListItem NavDrawer_ListItem--top-border"
            >
              <SkyButton
                v-if="child.children && child.children.length"
                :ref="(el) => (menuItemRefs[child.id] = el as SkyButtonRef)"
                class="NavDrawer_MenuItem"
                data-test-id="expanded-expand"
                right-icon="chevron-right"
                text
                @click.stop="expandMenuItem(child)"
              >
                {{ child.text }}
              </SkyButton>
              <SkyLink
                v-else
                :to="child.href"
                class="NavDrawer_MenuItem"
                :class="{
                  'NavDrawer_MenuItem--with-badge':
                    child.text === ROUTES.impactRoute.text ||
                    child.text === ROUTES.membershipSettings.text,
                }"
                data-test-id="expanded-link"
                @click="emit('hide')"
              >
                {{ child.text }}
                <template
                  v-if="
                    child.text === ROUTES.membershipSettings.text &&
                    showVipBadge
                  "
                >
                  <SkyBadge
                    class="NavDrawer_MenuItem--join-badge"
                    data-test-id="join-badge"
                    >Join</SkyBadge
                  >
                </template>
              </SkyLink>
            </li>
          </ul>
        </nav>
      </div>
    </FocusTrap>
  </div>
</template>

<style lang="scss">
.NavDrawer {
  position: fixed;
  top: var(--drawer-top-offset, 0);
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: var(--z-index-popout);

  &_Container {
    height: 100%;
    width: 90vw;
    position: relative;
    top: 0;
    max-width: 330px;
    overflow-y: auto;
    background: var(--surface-color-dark);
    box-shadow: 0 0 var(--spacing-3x) var(--spacing-halfx)
      var(--surface-color-darker);
    padding-bottom: var(--spacing-24x);

    &--white-background {
      background: var(--surface-color-default);
    }

    .SkyLink,
    .SkyButton {
      color: var(--text-color-action-primary-ondark);
      border: none;
    }
  }

  &_AppBadge {
    height: 48px;
    margin: var(--spacing-3x) 0;
    object-fit: contain;
  }

  &_GroveWellnessLogo {
    padding-left: var(--spacing-4x);
    .SkyImage_Image {
      object-fit: unset;
    }
  }

  &_Header {
    padding-left: var(--spacing-6x);
    background: var(--surface-color-default);

    .SkyLink,
    .SkyButton {
      color: var(--text-color-primary);
    }

    &_ReferButton.SkyButton {
      border: 2px solid var(--text-color-action-primary);
      color: var(--text-color-action-primary);
      padding: 0 var(--spacing-2x);
      font-size: var(--font-size-75);
    }

    &_Account {
      padding: var(--spacing-2x) var(--spacing-2x) 0 var(--spacing-4x);
      display: grid;
      grid-template-columns: 0.25fr 0.75fr 1.25fr;
      align-items: center;
      grid-gap: var(--spacing-2x);
      line-height: var(--line-height-tight);

      .SkyLink,
      .SkyButton {
        font-size: var(--font-size-75);
        text-align: left;
      }

      &_Greeting {
        font-size: var(--font-size-50);
      }
    }

    &_Education {
      padding: var(--spacing-2x) 0 var(--spacing-2x) var(--spacing-6x);
    }
  }

  &_Heading {
    @include type-heading('2');
    color: var(--text-color-primary);
    margin: 0;
    padding: var(--spacing-6x);

    &_Education {
      color: var(--text-color-action-primary-ondark);
    }

    &_Link {
      display: grid;
      align-items: baseline;
      grid-template-columns: 1fr auto;
      padding-right: var(--spacing-6x);

      &_ShopAll {
        color: var(--text-color-secondary);
        border-bottom: solid 2px var(--text-color-secondary);
        font-size: var(--font-size-75);
      }
    }
  }

  &_List {
    display: inline-block;
    width: 100%;
    margin: 0;
    padding: 0 var(--spacing-6x) 0 var(--spacing-6x);
    list-style: none;
    background: var(--surface-color-default);

    &Item {
      padding: 0;

      &--top-border {
        border-top: 1px solid var(--border-color-default);
      }
    }

    .SkyLink,
    .SkyButton {
      color: var(--text-color-primary);
      border: none;
    }

    &_Education {
      display: block;
      background: var(--surface-color-dark);

      .SkyLink,
      .SkyButton {
        color: var(--text-color-action-primary-ondark);
      }

      .SkyButton {
        font-family: var(--font-family-default-medium);
        font-weight: var(--font-weight-medium);
      }
    }
  }

  &_MenuItem {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: var(--spacing-4x) 0;
    @include type-body(m);
    &.SkyButton,
    &.SkyLink {
      line-height: var(--line-height-tight);
    }

    &--with-badge {
      justify-content: start;
      --text-color-action-disabled: var(--surface-color-default);

      .SkyBadge {
        margin-left: var(--spacing-2x);
      }
    }

    &--join-badge {
      --surface-color-dark: var(--text-color-vip-primary-ondark);
    }

    &--back {
      @include type-body(s, true);
      justify-content: flex-start;

      &.SkyButton {
        padding: var(--spacing-4x) 0;
        // Compensate for chevron-left icon
        margin: 0 0 0 calc(var(--spacing-2x) * -1);
      }
    }

    &--primary {
      @include type-heading('1');
      &.SkyButton,
      &.SkyLink {
        font-size: var(--font-size-100);
        line-height: var(--line-height-default);
      }
    }

    &--vip {
      --text-color-primary: var(--text-color-vip);
      --font-size-100: var(--font-size-300);
    }

    &--dot {
      justify-content: start;
      align-items: unset;
      gap: var(--spacing-1x);

      &:after {
        content: 'You have a new gift to choose';
        border-radius: var(--border-radius-pill);
        box-shadow: 0 0 0 1px var(--color-frost);
        background: var(--surface-color-vip);
        display: block;
        height: var(--spacing-2x);
        overflow: hidden;
        width: var(--spacing-2x);
      }
    }
  }

  &_BottomImages {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr 1fr;
    grid-gap: var(--spacing-2x);
    align-items: center;
    justify-items: center;
  }
}
</style>
