<script setup lang="ts">
import { ROUTES } from '~/constants/routes'
const { menuItems } = useMenuItems()
const { isLoggedIn, isVip } = useCustomer()

const showVipDot = computed(() => {
  // Restore once useVipGiftPicker is ported
  // if (isVip.value) {
  //   const { hasNotification } = useVipGiftPicker()
  //   return hasNotification.value
  // }
  return false
})
</script>

<template>
  <div class="HeaderMenu">
    <div class="HeaderMenu_Container">
      <nav aria-label="Catalog" class="HeaderMenu_Nav">
        <ul v-if="menuItems" class="HeaderMenu_List">
          <HeaderMenuListItem
            v-for="menuItem in menuItems"
            :key="menuItem.id"
            v-bind="menuItem"
          />
        </ul>
      </nav>
      <SkyLink
        v-if="isLoggedIn"
        :to="ROUTES.vipHub.to"
        class="HeaderMenu_Link"
        data-test-id="vip-hub"
      >
        <SkyBadge v-if="!isVip">Join VIP</SkyBadge>
        <div v-else>VIP Hub</div>
        <span
          v-if="showVipDot"
          class="HeaderMenu_LinkBadge"
          data-test-id="vip-dot"
        >
          <SkyScreenReader>You have a new gift to choose</SkyScreenReader>
        </span>
      </SkyLink>
    </div>
  </div>
</template>

<style lang="scss">
.HeaderMenu {
  @include container-large;
  background-color: var(--surface-color-default);
  border-bottom: solid 1px var(--border-color-default);
  position: relative;

  @include for-large-up {
    --container-margin: var(--spacing-8x);
  }

  &_Container {
    display: grid;
    grid-template-columns: auto auto;
    gap: var(--spacing-3x);
    justify-content: center;
  }

  &_Nav {
    overflow-x: hidden;
  }

  &_List {
    display: flex;
    margin: 0;
    height: 100%;
    padding-inline-start: 0;
  }

  &_Link {
    display: flex;
    align-items: center;
    position: relative;
    --text-color-action-primary: var(--text-color-vip);
    --text-color-action-primary-hover: var(--text-color-action-primary);
    padding: var(--spacing-3x) 0 var(--spacing-2x);

    &:hover {
      border-color: transparent;
    }

    .SkyBadge {
      color: var(--text-color-vip);
      background-color: var(--surface-color-default);
      border: 2px solid var(--text-color-vip);
      font-size: var(--font-size-100);
    }
  }

  &_LinkBadge {
    background: var(--surface-color-vip);
    border-radius: var(--border-radius-pill);
    display: block;
    position: absolute;
    aspect-ratio: 1;
    overflow: hidden;
    transition: 0.2s ease-in-out;
    top: var(--spacing-3x);
    right: calc(var(--spacing-3x) * -1);
    width: var(--spacing-2x);
    height: var(--spacing-2x);
  }
}
</style>
